import axios from "axios";
import authHeader from "./auth-header";
import AuthService from "./auth.service";
import { config } from "../config";

class Contracts {
  getEquipments(idContract) {
    return axios
      .get(`${config["apiUrl"]}contracts/${idContract}/equipments`, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  searchEquipments(term) {
    return axios
      .get(`${config["apiUrl"]}equipments/search/${term}`, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }
  searchContracts(term) {
    return axios
      .get(`${config["apiUrl"]}contracts/search/${term}`, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }
  toExpiring(months) {
    return axios
      .get(`${config["apiUrl"]}contracts/expiring/${months}`, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }
  countToExpiring(months) {
    return axios
      .get(`${config["apiUrl"]}contracts/expiring/${months}/count`, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  getExpired() {
    return axios
      .get(`${config["apiUrl"]}contracts/expired`, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }
  countExpired() {
    return axios
      .get(`${config["apiUrl"]}contracts/expired/count`, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }
  getEmailsTypes() {
    return axios
      .get(`${config["apiUrl"]}contracts/expiring/emailsType`, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  sendMessage(contractId, formData) {
    let headers = authHeader();
    headers["Content-Type"] = "multipart/form-data";
    return axios
      .post(
        `${config["apiUrl"]}contracts/expiring/${contractId}/message`,
        formData,
        {
          headers: headers,
        }
      )
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }
  changeSeller(contractId, bodyparms) {
    return axios
      .patch(
        `${config["apiUrl"]}contracts/expiring/${contractId}/seller`,
        bodyparms,
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }
  changeExternalManager(contractId, bodyparms) {
    return axios
      .patch(
        `${config["apiUrl"]}contracts/expiring/${contractId}/externalManager`,
        bodyparms,
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }
}
export default new Contracts();
