<template>
  <div>
    <Dialog
      :visible.sync="show"
      :style="{ width: $isMobile() ? '100vw' : '45vw' }"
      :header="'Histórico'"
      :modal="true"
      :closable="false"
    >
      <div v-if="tickets && tickets.length > 0">
        <DataTable
          :value="tickets"
          :paginator="false"
          sortable="true"
          :responsive="true"
        >
          <Column field="id" header="Ticket ID">
            <template #body="slotProps">
              <span
                class="clickable-item"
                @click="openTicketDetail(slotProps.data.id)"
              >
                <strong>#{{ slotProps.data.id }}</strong>
              </span>
            </template>
          </Column>

          <Column field="subject" header="Assunto"></Column>

          <Column field="created_at" header="Data" sortable="true">
            <template #body="slotProps">
              {{ formatDate(slotProps.data.created_at) }}
            </template>
          </Column>
        </DataTable>
      </div>
      <div v-else>
        <p>Não existe histórico.</p>
      </div>
      <template #footer>
        <Button
          label="Fechar"
          icon="pi pi-times"
          class="p-button-danger"
          @click="cancel"
        />
      </template>
    </Dialog>
  </div>
</template>
<script>
import ticketsService from "../../services/tickets.service";
import { getOnlyDate } from "../../helpers/helpers";

export default {
  name: "TicketHistory",
  props: ["show", "type", "equip_id", "ticket"],
  data() {
    return {
      tickets: [],
    };
  },
  watch: {
    show() {
      if (this.show === true) {
        return this.getTicketHistory();
      }
    },
  },
  methods: {
    getTicketHistory() {
      let serviceCall;
      let callID;
      switch (this.type) {
        case "equipment":
          serviceCall = ticketsService.getTicketHistoryEquipment;
          callID = this.equip_id;
          break;
        case "contract":
          serviceCall = ticketsService.getTicketHistoryContract;
          callID = this.ticket.id;
          break;
        case "entity":
          serviceCall = ticketsService.getTicketHistoryEntity;
          callID = this.ticket.id;
          break;
        default:
          console.error("Invalid type:", this.type);
          return;
      }

      serviceCall(callID)
        .then((response) => {
          this.tickets = response;
        })
        .catch((error) => {
          console.error("Error fetching ticket history:", error);
          this.$toast.add({
            severity: "error",
            summary: "Erro ao recolher histórico",
            detail: error.message || "Tente novamente mais tarde.",
            life: 5000,
          });
        });
    },
    formatDate(date) {
      return getOnlyDate(new Date(date));
    },
    openTicketDetail(ticketId) {
      window.open(`/helpdesk-tickets-detail/${ticketId}`, "_blank");
    },
    cancel() {
      this.$emit("cancel");
    },
  },
};
</script>
<style scoped>
.ticket-link {
  color: black;
  text-decoration: none;
}

.ticket-link:hover {
  text-decoration: underline;
}
</style>
